@import 'styles/includes.scss';
@import 'styles/variables.scss';

.CrossLinks {
    font-family: $fontBrand;
    padding-bottom: 8rem;

    &--MobileOnly {
        padding: 20px 0 0 0;
        @include media(m) {
            display: none;
        }
    }

    &__Container {
        @extend %container;
    }

    &__Links {
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__Title {
        display: block;
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 3.43rem;
        letter-spacing: 0.04rem;
        color: $colorBlack800;
        margin-bottom: 28px;
        @include media(l) {
            font-size: 3.5rem;
            line-height: 4.6rem;
            letter-spacing: 0.08rem;
            margin-bottom: 31px;
        }
    }

    & .QuickLink {
        margin-bottom: $spacingL;
    }

    & .QuickLink:not(:last-of-type) {
        @include media(l) {
            margin-right: $spacingXl;
        }
    }
}
